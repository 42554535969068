.searchContainer {
  display: flex;
  align-content: center;
  justify-content: center;
  margin-top: 20px;
  .searchBar {
    background-image: url("../../../../../assets/img/icons/common/search.svg");
    background-size: 90px 30px;
    background-repeat: no-repeat;
    background-position: center left;
    text-indent: 55px;
    padding: 10px 3px;
    width: 50%;
    padding: 20px;
    border-radius: 10px;
    border: none;
    background-color: hsla(0, 6%, 96%, 1);
    font-size: 20px;
    outline: none;
    &::placeholder {
      color: hsla(0, 5%, 82%, 1);
    }
  }
}
@media only screen and (max-width: 900px) {
.searchContainer{
  .searchBar{
    width: 80%;
  }
}  
}
@media only screen and (max-width: 718px) {
  .searchContainer{
    .searchBar{
      width: 90%;
    }
  } 
}