/* homePage.module.css */
.header {
  background-color: #c2f8c2;
  text-align: center;
  text-transform: capitalize;
  padding: 0.25rem 0;
}

.head {
  color: #242f6c;
  font-size: 2.5rem;
  font-weight: bold;
  padding-bottom: 0.25rem;
}

.subHead {
  color: #36dcd1;
  font-size: 1rem;
}
.verify {
  color: #242f6c;
}

.body {
  text-align: center;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ver {
  background-color: #242f6c;
  border-radius: 0.5rem;
  color: #ffffff;
  font-size: 1.875rem;
  font-weight: bold;
  padding: 0.75rem 0;
  width: 33.333333%;
}

.select {
  background-color: #c2f8c2;
  border-radius: 0.5rem;
  text-align: center;
  width: 33.333333%;
}

.tId {
  padding: 1rem 0;
}

.selection {
  background-color: #ffffff;
  border-radius: 0.5rem;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  margin-top: 0.625rem;
  padding: 0.5rem;
  position: absolute;
  width: 33.333%;
  display: flex;
  flex-direction: column;
}

.cursorPointer {
  cursor: pointer;
  justify-self: end;
}

.cancel {
  cursor: pointer;
  height: 32px;
  width: auto;
  align-self: end;
}

.iftaar,
.sahoor {
  cursor: pointer;
  padding: 0.75rem 0;
  text-transform: capitalize;
}

.iftaar:hover,
.sahoor:hover {
  background-color: #edf2f7;
}

@media (max-width: 500px) {
  .body,
  .ver,
  .select {
    width: 90%;
  }

  .selection {
    width: 75%;
  }
}
