.loginWrapper {
	display: flex;
	min-height: 100vh;
	padding: 80px 0;
	background: #f9f9f9;

	.loginForm {
		position: relative;
		z-index: 1;
		box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
		margin-inline: auto;
		padding: 60px 50px !important;
		border-radius: 10px;
		width: 500px;
		max-width: 90%;
		background: #fff;

		.shape-img {
			position: absolute;
			left: 50%;
			top: 50%;
			z-index: -1;
			width: 80%;
			height: 80%;
			opacity: 0.18;
			transform: translate(-50%, -50%);
		}

		@media (max-width: 445px) {
			position: relative;
			padding: 70px 15px !important;
		}

		.header {
			> p {
				margin-bottom: 30px;
				font-size: 13px;
				font-weight: 500;
				color: #666666;
			}
		}

		form {
			.inputOutline {
				label {
					font-size: 16px;
					background: #fff;
					padding: 0 10px 0 0;
				}

				input {
					font-size: 13px;
					letter-spacing: 0;
				}

				input::-webkit-input-placeholder {
					color: #888888;
					opacity: 1;
				}

				input::-moz-placeholder {
					color: #888888;
					opacity: 1;
				}

				input:-ms-input-placeholder {
					color: #888888;
					opacity: 1;
				}

				input:-moz-placeholder {
					color: #888888;
					opacity: 1;
				}
			}

			.error-msg {
				margin-top: 0.3em;
				margin-bottom: 0rem;

				div {
					font-size: 0.8rem;
				}
			}

			.formAction {
				display: flex;
				justify-content: space-between;
				flex-wrap: nowrap;
				align-items: center;

				label {
					span {
						font-size: 14px;

						svg {
							width: 1.2em;
							height: 1.2em;
						}
					}
				}

				a {
					display: block;
					letter-spacing: 0;
					font-size: 13px;
					font-weight: 500;
					color: #246fea;
					cursor: pointer;
					transition: all 0.3s ease 0s;
				}
			}

			.formFooter {
				margin-top: 20px;
				display: flex;

				.cBtnTheme {
					padding-block: 0.8em;
					background: #02a533;
					background-color: #02a533 !important;
					color: #fff !important;
				}
			}
		}

		.loginWithSocial {
			display: flex;
			justify-content: center;
			width: 320px;
			margin: 20px auto 0;

			@media (max-width: 445px) {
				width: 185px !important;
			}

			button {
				min-width: unset;
				flex: 1 1;
				display: block;
				text-align: center;
				background: #3b5998;
				margin: 5px;
				color: #fff;
				-webkit-border-radius: 3px;
				-moz-border-radius: 3px;
				border-radius: 3px;
				font-size: 14px;
				padding: 4px 9px;

				&.facebook {
					background: #3b5998;
				}

				&.twitter {
					background: #55acee;
				}

				&.linkedin {
					background: #0077b5;
				}
			}
		}

		.noteHelp {
			text-align: center;
			margin-top: 20px;
			font-size: 13px;
			letter-spacing: 0;
			font-weight: 500;

			a {
				display: inline-block;
				color: #0077b5;
				margin-left: 10px;
				font-size: 13px;
				font-weight: 500;
				cursor: pointer;
			}
		}
	}
}
