.ticketContainer {
  background-color: #f0f0f0;
  border-radius: 10px;
  padding: 20px;
  margin: 20px auto;
  max-width: 500px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.ticketInfo {
  margin-top: 20px;
}

.ticketInfo p {
  margin: 5px 0;
}

.ticketInfo p strong {
  font-weight: bold;
}
.button {
    /* Tailwind CSS classes */
    font-size: 2rem; /* Equivalent to text-2xl */
    text-transform: uppercase; /* Equivalent to uppercase */
    width: 100%; /* Equivalent to w-full */
    text-align: center; /* Equivalent to text-center */
    margin-top: 2.5rem; /* Equivalent to mt-20 */
    background-image: linear-gradient(
      to right,
      #c2f8c2,
      #1f932a
    ); /* Equivalent to bg-gradient-to-r */
    padding: 0.75rem; /* Equivalent to p-3 */
    border-radius: 0.5rem; /* Equivalent to rounded-md */
    color: white; /* Equivalent to text-white */
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2); /* Equivalent to shadow-custom */
    cursor: pointer;
  }