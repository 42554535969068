.thumb-sm {
    height: 36px;
    width: 36px;
}
.card-box {
    padding: 10px;
    border-radius: 3px;
    /* margin-bottom: 30px; */
    background-color: #fff;
}

.taskList {
    min-height: 40px;
    /* margin-bottom: 50px */
}

.taskList li {
    background-color: #fff;
    border: 1px solid rgba(121, 121, 121, .2);
    padding: 10px;
    /* margin-bottom: 20px; */
    border-radius: 3px
}

.taskList li:last-of-type {
    margin-bottom: 0
}

.taskList a {
    font-size: 13px
}

.taskList .checkbox {
    margin-left: 20px;
    margin-top: 5px
}
.text-muted {
    color: #98a6ad!important;
}