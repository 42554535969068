ul.smothscroll {
    position: fixed;
    bottom: 25px;
    right:30px;
    list-style: none;
    z-index: 99;
}
ul.smothscroll a {
    background-color: rgba(0, 198, 209, 0.7);
    width: 45px;
    height: 45px;
    line-height: 40px;
    border: 2px solid #00d169;
    border-radius: 0;
    display: block;
    text-align: center;
    color: #fff;
    border-radius: 50%;
}
ul.smothscroll a:hover {
    background-color: #02a533;
}

@media(max-width:767px){
    ul.smothscroll a {
        width: 30px;
        height: 30px;
        line-height: 25px;
    }
}