.section {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  img{
    width: 20px;
    height: 20px;
  }
  .cardbackground {
    background: white;
    border-radius: 30px 0 0 0;
  }
}
@media only screen and (max-width: 1082px) {
  .section {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width: 636px) {
  .section {
    grid-template-columns: repeat(1, 1fr);
  }
}
