.section-paddin {
    padding-bottom: 100px !important;
}

.index {
    z-index: 100000;
}

.pointer {
    cursor: pointer !important;
}

.contain {
    height: '200px';
}

.contains {
    position: relative !important;
}
  
.overlay {
    position: absolute; 
    bottom: 0; 
    background: rgb(0, 0, 0);
    background: rgba(0, 0, 0, 0.5); /* Black see-through */
    color: #f1f1f1; 
    width: 100%;
    transition: .5s ease;
    opacity:0;
    color: white;
    padding: 10px;
    text-align: center;
    cursor: pointer !important;
  }
  
.contain:hover .overlay {
    opacity: 1;
}

@media(max-width:768px) {
    .image {
        width: 90% !important;
    }
}