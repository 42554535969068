/* modal.module.css */
.backdrop {
  /* Tailwind CSS classes */
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
  /* Custom styles */
  width: 100%;
  height: 100%;
}

.modalDialog {
  /* Tailwind CSS classes */
  overflow: hidden;
  width: 33.333333%;
  border: none;
  border-radius: 0.5rem;
  justify-self: center;
  background-color: white;
  /* Custom styles */
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
}
@media (max-width: 500px) {
  .modalDialog {
    width: 85%;
  }
}
