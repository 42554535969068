/* Content Loader */
.content-loader {
    animation: loader 1.5s infinite ease-in-out;
    -webkit-animation: loader 1.5s infinite ease-in-out;
}
@keyframes loader {
    0% {
        background-color: rgba(165, 165, 165, 0.15);
    }
    50% {
        background-color: rgba(165, 165, 165, 0.35);
    }
    100% {
        background-color: rgba(165, 165, 165, 0.15);
    }
}
@-webkit-keyframes loader {
    0% {
        background-color: rgba(165, 165, 165, 0.15);
    }
    50% {
        background-color: rgba(165, 165, 165, 0.35);
    }
    100% {
        background-color: rgba(165, 165, 165, 0.15);
    }
}
