.success-payment-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; /* Center items horizontally */
  gap: 5px;
}

.success-payment-container img {
  width: 10vw; /* Use 10% of the viewport width */
  height: auto; /* Maintain aspect ratio */
}

.success-payment-container h1 {
  font-size: 2rem; /* Increase font size for larger screens */
  margin: 0;
}

.success-payment-container strong {
  font-weight: bold;
}

.payment-details {
  max-width: 90%; /* Limit width to 90% of the parent container */
  width: 500px; /* Default width */
  height: auto; /* Adjust height based on content */
  background-color: #c2f8c2;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

.payment-details-title {
  background-color: #6cdd77;
  height: 5vw;
  max-width: 100%;
  color: #fff;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: 10vw;
  border-radius: 15px;
  margin: 0 auto;
}

.payment-details-items {
  margin: 0 auto;
}

.payment-details-item {
  display: flex;
  justify-content: space-between;
  align-items: center; /* Center items vertically */
  margin-bottom: 5px;
}

.payment-details-item strong {
  font-weight: bold;
}

.payment-details-item button {
  background-color: #1f932a;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .success-payment-container h1 {
    font-size: 1.5rem; /* Decrease font size for medium screens */
  }
  
  .payment-details {
    width: 90%; /* Adjust width for medium screens */
  }
  
  .payment-details-title {
    height: 15vw;
    font-size: 1.25rem; /* Decrease font size for medium screens */
    line-height: 7vw; /* Adjust line height for medium screens */
  }
  
  .payment-details-item {
    flex-direction: column; /* Stack items vertically on medium screens */
    align-items: flex-start; /* Align items to the start on medium screens */
  }
}
